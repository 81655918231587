import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Hero from "../../components/Hero/Hero"
import OneColumn from "../../components/Content/OneColumn"
import TwoColumnWithImage from "../../components/Content/TwoColumnWithImage"
import TwoColumnGeneric from "../../components/Content/TwoColumnGeneric"
import ThreeColumnWithIcon from "../../components/Content/ThreeColumnWithIcon"
import ThreeColumnGrid from "../../components/Content/ThreeColumnGrid"
import ThreeColumnCards from "../../components/Content/ThreeColumnCards"
import SEO, { seoPropsMapping } from "../../components/seo"
import MarketoForm from "../../components/MarketoForm"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../../components/VindiciaComponents"

const BecomePartner = ({ data, location }) => {
  const section = data.prismicBecomePartnerPage.data
  const partnerCompanies = data.allPrismicPartnerCompanies.edges

  const twoColumnSection = section => {
    switch (section.primary.section_id) {
      case "leverage":
        return (
          <div className="uk-grid uk-grid-medium uk-grid-match uk-child-width-1-2@m" data-uk-grid>
            <div>
              <div className="uk-panel uk-padding uk-box-shadow-small uk-border-rounded">
                <div dangerouslySetInnerHTML={{ __html: section.primary.column_one.html }} />
              </div>
            </div>
            <div>
              <div className="uk-panel uk-padding uk-box-shadow-small uk-border-rounded">
                <div dangerouslySetInnerHTML={{ __html: section.primary.column_two.html }} />
              </div>
            </div>
          </div>
        )
      case "partners-antifraud":
        return (
          <div className="uk-grid uk-grid-medium uk-child-width-1-2@s" data-uk-grid>
            <div>
              <h2 className="uk-text-center uk-margin-bottom">{section.primary.column_one.text}</h2>
              <div
                className="uk-grid uk-child-width-1-2@m uk-margin-auto-left uk-margin-auto-right"
                data-uk-grid
              >
                {partnerCompanies.map((item, i) => (
                  <div
                    key={item.node.uid}
                    style={{ backgroundColor: "#fff" }}
                    className=" uk-padding-small uk-text-center uk-box-shadow-small uk-border-rounded"
                  >
                    <Image
                      src={item.node.data.logo.fixed.src}
                      srcSet={item.node.data.logo.fixed.srcSet}
                      alt="Company Logo"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div
              className={`${section.slice_label &&
                section.slice_label.includes("text-right") &&
                "uk-flex-first@m"}`}
            >
              <div
                className="uk-width-expand uk-padding-small uk-visible-toggle uk-align-right"
                data-uk-slider="autoplay: true"
              >
                <div
                  className="uk-slider-items uk-child-width-1-1"
                  data-uk-grid
                  data-uk-height-match="target: > div > .uk-card"
                >
                  {partnerCompanies.map((card, i) => (
                    <div key={`card-${i}`}>
                      <div
                        key={`card-${i}`}
                        className="uk-card uk-card-default uk-card-small uk-border-rounded"
                      >
                        <div className="uk-card-body">
                          <Image
                            src={require(`../../images/become-partner/bp${i + 1}.jpg`)}
                            style={{ width: "100%" }}
                            className="uk-border-rounded"
                            alt="Generic image"
                          />
                          <div
                            dangerouslySetInnerHTML={{ __html: card.node.data.description.html }}
                          />
                        </div>
                        <div className="uk-card-footer uk-text-center">
                          <Image
                            src={card.node.data.logo.fixed.src}
                            srcSet={card.node.data.logo.fixed.srcSet}
                            alt="Company Logo"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <ul className="uk-slider-nav uk-dotnav uk-margin-medium-top uk-flex-center">
                  <li></li>
                </ul>
              </div>
            </div>
          </div>
        )
      case "joinform":
        return (
          <div className="uk-grid uk-grid-medium uk-grid-match uk-child-width-1-2@m" data-uk-grid>
            <div>
              {section.primary.column_one && section.primary.column_one.html.length > 10 ? (
                <div dangerouslySetInnerHTML={{ __html: section.primary.column_one.html }} />
              ) : (
                ""
              )}
            </div>
            <div
              className={`uk-margin-medium ${section.slice_label &&
                section.slice_label.includes("text-right") &&
                "uk-flex-first"}`}
            >
              <MarketoForm formId="1572" domainAddr="//app-aba.marketo.com" />
            </div>
          </div>
        )
      default:
        return null
    }
  }

  const contentType = section => {
    let t
    const type = section.slice_type

    switch (type) {
      case "1-column_container-small":
        t = <OneColumn key={`section-${section.primary.section_id}`} data={section} />
        break
      case "2-column":
        t = <TwoColumnWithImage key={`section-${section.primary.section_id}`} data={section} />
        break
      case "3-column_with_icon":
        t = <ThreeColumnWithIcon key={`section-${section.primary.section_id}`} data={section} />
        break
      case "3-column_grid":
        t = <ThreeColumnGrid key={`section-${section.primary.section_id}`} data={section} />
        break
      case "2-column-generic":
        t = (
          <TwoColumnGeneric key={`section-${section.primary.section_id}`} data={section}>
            {twoColumnSection(section)}
          </TwoColumnGeneric>
        )
        break
      case "3-column-cards":
        t = (
          <ThreeColumnCards key={`section-${section.primary.section_id}`} data={section}>
            {section.items.map((item, i) => (
              <li key={`card-${i}`}>
                <div className="uk-card uk-card-default uk-card-small" data-uk-card>
                  <div className="uk-card-media-top">
                    <Image
                      src={item.card_header_image.fluid.src}
                      srcSet={item.card_header_image.fluid.srcSet}
                      alt="Vindicia"
                    />
                  </div>
                  <div className="uk-card-body">
                    <div dangerouslySetInnerHTML={{ __html: item.card_body_content.html }} />
                  </div>
                </div>
              </li>
            ))}
          </ThreeColumnCards>
        )
        break
      case "embed_code":
        t = (
          <section className="uk-section uk-container">
            <div key={section.primary.section_id}>
              <div dangerouslySetInnerHTML={{ __html: section.primary.embed_code }}></div>
            </div>
          </section>
        )
        break
      default:
        break
    }
    return t
  }

  const seoProps = seoPropsMapping(section)

  return (
    <Layout>
      <SEO {...seoProps} />
      <div className={`partners-container`}>
        <Hero data={section} path={location.pathname} />
        {section.body.map((section, i) => contentType(section))}
      </div>
    </Layout>
  )
}

export default withPreview(BecomePartner)

export const becomePartnerQuery = graphql`
  {
    prismicBecomePartnerPage {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        page_title
        hero_title {
          html
        }
        hero_text_alignment
        hero_subtitle
        hero_button_label
        hero_button_link
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        body {
          ... on PrismicBecomePartnerPageBody2Column {
            primary {
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              section_id
              section_name
              section_theme
              section_background_color
              muted_background
            }
            slice_label
            slice_type
          }
          ... on PrismicBecomePartnerPageBody2ColumnGeneric {
            id
            primary {
              column_one {
                html
                text
              }
              column_two {
                html
              }
              section_id
              section_name
              section_title
              section_subtitle {
                html
              }
              muted_background
            }
            slice_label
            slice_type
          }

          ... on PrismicBecomePartnerPageBody3ColumnCards {
            id
            items {
              card_body_content {
                html
              }
              card_header_image {
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            primary {
              section_id
              section_title_centered {
                html
                text
              }
              muted_background
            }
            slice_type
          }
          ... on PrismicBecomePartnerPageBodyEmbedCode {
            id
            slice_label
            slice_type
            primary {
              section_id
              section_name
              embed_code
            }
          }
        }
      }
    }
    allPrismicPartnerCompanies(filter: { uid: { regex: "/(verifi)|(cybersource)/" } }) {
      edges {
        node {
          uid
          data {
            company_name
            logo {
              fixed(width: 150) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            description {
              html
            }
            company_url {
              url
            }
          }
        }
      }
    }
  }
`
